import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from "axios";
import ElementPlus from 'element-plus'

axios.defaults.timeout = 5000       // 超时时长
axios.defaults.baseURL = "/"    // 访问地址

createApp(App)
    .use(store)
    .use(router)
    .use(ElementPlus)
    .mount('#app')
