<template>
    <div id="loading">
        <div class="mdui-spinner mdui-spinner-colorful" style="width: 50px; height: 50px"></div>
    </div>

    <l-delay time="200">
        <router-view/>
    </l-delay>

</template>

<script>
import LDelay from "./components/delay"
export default {
    name: "App",
    components: {
        LDelay,
    },
    methods: {
        // 获取 & 保存配置数据
        changeData() {
            this.$store.dispatch("changeData")
        }
    },
    mounted() {
        this.changeData()
        setTimeout(() => {
            const loading = document.getElementById("loading")
            loading.innerHTML = ""
        }, 200)
    }
}

</script>

<style lang="less">
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body {
    background-size: auto;
}
#loading {
    position: absolute;
    top: 50%;
    left: 50%;
}
</style>
