import { createStore } from 'vuex'
import axios from "axios"

export default createStore({
  state: {
    // 全局配置文件
    config: {},
    // Login配置文件
    LoginConfig: {},
  },
  getters: {
  },
  mutations: {
    webConfig() {
      // 获取配置数据
      axios.defaults.headers.common['Authorization'] = "Bearer" + " " + localStorage.getItem("Bearer");
      axios.get("api/data").then((res) => {
        if (res.data.code === 2005) {
          // 设置跳转
          this.state.config = 2005
          axios.get("api/ldata").then((res) => {
            this.state.LoginConfig = res.data.data
          })
        } else if (res.data.code === 2004) {
          this.state.config = 2005
          axios.get("api/ldata").then((res) => {
            this.state.LoginConfig = res.data.data
          })
        } else {
          // 修改配置数据
          this.state.config = res.data.data
        }
      }).catch((err) => {
        // 获取数据失败
        this.state.config = err
      })
    },
    tWebConfig() {
      axios.defaults.headers.common['Authorization'] = "Bearer" + " " + localStorage.getItem("Bearer");
      axios.get("v1/api/data").then((res) => {
        if (res.data.code === 2005) {
          // 设置跳转
          this.state.config = 2005
        } else if (res.data.code === 2004) {
          this.state.config = 2005
          axios.get("api/ldata").then((res) => {
            this.state.LoginConfig = res.data.data
          })
        } else {
          // 修改配置数据
          this.state.config = res.data.data
        }
      }).catch((err) => {
        // 获取数据失败
        this.state.config = err
      })
    }
  },
  actions: {
    // 执行 changeData 方法
    changeData(){
      axios.get("api/f").then((res) => {
        // 判断访问路径
        if (res.data.data.IsLogin === 0) {
          this.commit("webConfig")
        } else {
          this.commit("tWebConfig")
        }
      }).catch((err) => {
        // 获取数据失败
        this.state.config = err
      })
    },
    tChangeData(){
      this.commit("tWebConfig")
    }
  },
  modules: {
  }
})
